<template>
  <v-col
    cols="auto"
    md="12"
    class="bg-secondary pa-0 ma-0"
    style="height: 80vh"
  >
    <p
      class="text-h4 pt-4 pl-2 text-left py-6 my-auto"
      style="
        background-color: #fff;
        line-height: 1.5;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.32) !important;
      "
    >
      <b class="white">Mi carrito</b>
      <span class="ml-6 badge-carrito-cant-articulos">{{
          ($store.state.carrito.cantItem != null
            ? $store.state.carrito.cantItem
            : 0) +
          ($store.state.carrito.cantItem != 1 ? " Artículos" : " Artículo")
        }}</span>
    </p>

    <!-- Mostrar mensaje cuando el carrito está vacío -->
    <div
      v-if="!tieneProductos"
      style=" display: flex; flex-direction: column"
      class="carritoMsg__div px-2 d-flex flex-column justify-center align-items-center"
    >
      <div style="position: relative; display: inline-block;margin-top:70px">
        <v-icon
          color="text-center"
          style="font-size: 5rem; top: 0; left: 40%; pointer-events: none"
        >mdi-cart-outline
        </v-icon
        >

        <v-btn
          class="text-center primary"
          dark
          elevation="0"
          fab
          icon
          x-small
          style="position: absolute; top: 0; left: 47%; pointer-events: none"
        >
          <span style="font-size: 14px; padding: 5px">0</span>
        </v-btn>
      </div>
      <br/>
      <p style="line-height: 1; font-weight: 500" class="display-1 text-center">
        Tu carrito está vacio
      </p>
      <small style="line-height: 1; color: #999" class="text-center">
        Actualmente no hay productos en su carrito. Puede seguir comprando y
        agregar los productos deseados.
      </small>
    </div>

    <!-- Mostrar loading mientras los items se cargan -->
    <div
      v-if="isLoading"
      class="d-flex justify-center align-center"
      style="height: 300px"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <!-- Mostrar productos si el carrito tiene datos -->

    <div v-if="!isLoading && tieneProductos" class="bg-secondary pa-0 ma-0"
         style="display: flex; flex-direction: column">

      <!--style="overflow-y: scroll"-->
      <div style="  padding: 20px"

           v-for="(item, index) in carritoOrdenado"
           :key="item.ItemCode+'-'+item.db"
           class="py-1"

      >
        <div style="font-size: 12px" class="py-2"
             v-if=" item.ItemCode !='GT' && item.ItemCode !='GB' && item.ItemCode !='FLE'">
          <v-row
            class="py-2"
            v-if="
              (index == 0 ||
              item.TipoElementosDesc !=
                carritoOrdenado[index - 1].TipoElementosDesc)

            "
          >
            <v-col
              cols="12"
              md="9"
              class="py-0 pt-3 pl-4"
              style="color: #b2b2b2; font-weight: 500"
            >{{ item.TipoElementosDesc }}
            </v-col
            >
          </v-row>
          <v-row v-else class="pt-1"></v-row>
          <v-row class="py-2" style="background-color: #fff">
            <v-col
              cols="12"
              md="9"
              class="py-0 pt-2 pl-4"
              style="font-weight: 500"
            >{{ item.ForeignName | capitalizeFirst }}
            </v-col
            >
            <v-col cols="12" md="3" class="py-0 pt-2 pl-4 text-end pr-4">
              <!--<span class="row-producto-item-code">{{ item.ItemCode }}</span>-->
              <span
                class="custom-badge"
                :class="{
                  'badge-blue': item.db.substring(4, 6) === 'AC',
                  'badge-green': item.db.substring(4, 6) === 'MJ',
                }"
              >{{ item.db.substring(4, 6) }}</span
              >
            </v-col>
          </v-row>
          <v-row class="py-2" style="background-color: #fff">
            <v-col cols="12" md="1" class="py-0 mx-1 my-auto">
              <v-icon
                small
                class="mr-2"
                title="Eliminar"
                @click="dialogEliminar(item.ItemCode)"
                style="font-size: 22px"
              >
                mdi-delete-outline
              </v-icon>


              <dialog-confirmar
                v-model="dialogs[item.ItemCode]"
                @input="dialog = $event"
                :title="`¿Estás seguro de que deseas eliminar el producto <br>${capitalizeFirst(item.ForeignName)}?`"
                info="Esta acción no se puede deshacer."
                icon="mdi-alert-circle-outline"
                color="#F44336"
                @confirm="removeItem(item)"
              />
            </v-col>
            <v-col cols="12" md="6" class="py-0 pl-1">
              <div class="d-flex align-center">
                <v-btn
                  elevation="0"
                  x-small
                  class="btn-decrement-carrito btn-mas-menos-carrito"
                  @click="decrement(item)"
                  style="color: #000"
                  :class="
                    item.error != '' && item.error
                      ? 'btn-decrement-carrito-error'
                      : ''
                  "
                >
                  -
                </v-btn>

                <!-- Input de Unidades -->
                <v-text-field
                  v-model.number="item.unidades"
                  type="number"
                  @keydown="handleKeydown($event, item)"

                  @keyup="updateTotal(item, index)"
                  class="row-unidades-cart-2 center-number-input no-border"
                  hide-details
                  height="25"
                  :error-messages="item.error"
                  @input="item.TipoElementosId!=5?validateDivisor(item):validateCantStock(item)"
                  :class="
                    item.error != '' && item.error
                      ? 'row-unidades-cart-2-error'
                      : ''
                  "
                ></v-text-field>

                <v-btn
                  x-small
                  elevation="0"
                  class="btn-increment-carrito btn-mas-menos-carrito"
                  @click="increment(item)"
                  style="color: #000"
                  :class="
                    item.error != '' && item.error
                      ? 'btn-increment-carrito-error'
                      : ''
                  "
                >
                  +
                </v-btn>

              </div>
            </v-col>
            <v-col
              v-if="item.unidades > 0"
              cols="12"
              md="4"
              class="py-0 text-end pr-1"
              style="font-weight: 600"
            >
              {{ "$ " + $formatMoney(item.priceList.Price * item.unidades) }}
            </v-col>
            <v-col
              v-if="item.error != '' && item.error"
              cols="12"
              md="11"
              class="offset-1 py-0 text-start"
            >
              <span class="red--text text--small" style=""
              >{{ item.error }}
              </span>
            </v-col>
          </v-row>
          <v-row></v-row>
        </div>
      </div>

      <div
        v-if="$store.state.carrito.data.length != 0"
        class="carrito__div pa-0 ma-0"
        style="height: 20vh "
      >
        <p class="text-end mr-2 pb-6 pr-3">
          <a
            style="font-weight: 500; height: 4rem; color: #000"
            @click="dialogVaciar"
          >
            Vaciar Carrito
          </a>
        </p>

        <dialog-confirmar
          v-model="dialogs['carritoVaciar']"
          @input="dialog = $event"
          title="Vaciar carrito"
          info="¿Estás seguro de que quieres vaciar todos los articulos de tu carrito? Esta acción no se puede deshacer."
          icon="mdi-cart-outline"
          color="#F44336"

          @confirm="carritoVaciar()"
        />
      </div>

      <div class="mx-0 pb-0 mb-0" style="background-color: #fff; ">


        <v-row
          v-if="modSaldo"
          class="py-4 my-0 ml-2 py-2"
          style="font-size: 13px; color: #000; font-weight: 600"
        >
          <v-col cols="12" md="7" class="py-0 text-start">Subtotal

          </v-col>

          <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
            ${{
              $store.state.carrito.total
                ? $formatMoney($store.state.carrito.subtotal)
                : ""
            }}
          </v-col>
        </v-row>

        <v-row
          v-if="modSaldo && $store.state.carrito.subtotal > 9999999"
          class="my-0 ml-2 pb-2"
          style="color: #000; font-size: 12px"
        >
          <v-col
            cols="1"
            class="d-flex align-center justify-center py-0"
          >
            <v-icon
              color="primary"
              style="font-size: 30px"
            >
              mdi-information
            </v-icon>
          </v-col>

          <v-col
            cols="11"
            class="d-flex align-center py-0 text-left"
          >
          <span class="font-weight-bold">
            Para montos mayores a $9.999.999 el método de pago Boleta Banco Nación no estará disponible.
          </span>
          </v-col>
        </v-row>

        <v-row class="py-1 my-0 mx-0">
          <v-col cols="12" md="12" class="mx-auto">
            <!--<router-link :to="routeDetalle">-->

            <v-btn
              block
              color="btnPrimary"
              class="capitalize-first custom-btn"
              @click="dialogValidate"
            >
              {{ btnContinuarLabel }}
            </v-btn>
            <!--</router-link>-->

            <dialog-validate
              v-model="dialogs['carritoValidate']"
              @input="dialog = $event"
              title="Revisa tu carrito"
              info="Algunos productos en tu carrito tienen cantidades incorrectas. Por favor, revisa y corrige los campos antes de continuar con la compra"
              icon="mdi-alert-circle-outline"
              color="#ffc107"
            />


          </v-col>
        </v-row>
      </div>
    </div>
  </v-col>
</template>

<style scoped>
.custom-btn {
  text-transform: none;
  font-family: inherit;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}
</style>

<script>
import {mapState} from "vuex";
import {BaseButton} from "../../components/design-system";

import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import DialogValidate from "@/views/dialogs/Validate.vue";

export default {
  components: {
    BaseButton,


    DialogConfirmar,
    DialogValidate,
  },
  props: {
    mergeElementos: {
      type: Function, // Esperamos una función pasada desde el padre
      required: true,
    },
  },
  data() {
    return {
      previousValue: "",
      routeDetalle: "",
      modSaldo: true,

      dialogs: {
        carritoVaciar: false,
        carritoValidate: false,
      },
      isLoading: true,
    };
  },
  mounted() {
    this.loadData(); // Llama a la función para cargar los datos cuando el componente se monta
//    console.log("this.carritoOrdenado")
//    console.log(this.carritoOrdenado)
  },
  activated() {
    // Llama a la función para cargar los datos cada vez que el componente se activa
    this.loadData();
  },
  computed: {
    // Mapea el estado de Vuex a propiedades computadas
    ...mapState({
      carrito: (state) => state.carrito,
    }),
    carritoOrdenado() {
      return this.carrito.data
        .slice()
        .sort((a, b) => a.TipoElementosId - b.TipoElementosId);
    },

    // Total calculado
    totalCarrito() {
      return this.$store.state.carrito.total
        ? this.$formatMoney(this.carrito.total)
        : "";
    },

    carritoItemCount() {
      return this.carrito.data.length;
    },
    tieneProductos() {
      return this.carritoItemCount > 0;
    },
  },

  methods: {
    loadData() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;

        this.btnContinuarLabel = "Continuar compra";

        if (this.carritoOrdenado[0].TipoElementosId == 4 || this.carritoOrdenado[0].TipoElementosId == 5) {
          this.btnContinuarLabel = "Continuar venta";
        }

      }, 1000);
    },

    async updateTotal(item, index) {
      if (!item.error) {
        let cantPrevia = !isNaN(this.previousValue)
          ? Number(this.previousValue)
          : 0;

        this.$store.state.carrito.data[index].unidadesPrevia = cantPrevia;
        // Usa una mutation para agregar o actualizar el item en el carrito
        if (item.unidades > 0) {
          this.$store.commit("carrito/addOrUpdateItemManual", item);

          this.$forceUpdate();
        } else {
          this.$store.state.carrito.data[index].error =
            "El valor ingresado debe ser mayor que 0";

        }
      }
    },
    async validateDivisor(item) {
      let resp = await this.$store.dispatch("carrito/validateDivisor", item);
      return resp;
      //this.$forceUpdate();
    },
    async validateCantStock(item, operator = false) {
      let resp = await this.$store.dispatch("carrito/validarCantStock", item, operator);
      return resp;
      //this.$forceUpdate();
    },
    async increment(item) {

      if (item.TipoElementosId == 5) {
        let respStoc = await this.$validarCantStockCarrito(item, 'i');

        if (respStoc == false) {
          return;
        }
      }

      if (!item.error) {
        this.$store.commit("carrito/addCantidad", item);
      } else {

        let divisor = Number(item.unidades) / Number(item.SalesQtyPerPackUnit);
        let res = parseInt(Number(divisor));

        item.unidades = res * parseInt(Number(item.SalesQtyPerPackUnit));
        //this.$forceUpdate();

        let index = this.$store.state.carrito.data.findIndex(
          (i) => i.ItemCode === item.ItemCode
        );

        if (index !== -1) {
          this.carrito.data[index].error = "";
        }
        this.$store.commit("carrito/addCantidad", item);
      }
      this.mergeElementos();
      this.$forceUpdate();
    },
    async decrement(item) {
      let respStoc = false;
      if (item.TipoElementosId == 5) {
        respStoc = await this.$validarCantStockCarrito(item);
      }
      if (item.unidades > Number(item.SalesQtyPerPackUnit)) {
        if (!item.error) {
          this.$store.commit("carrito/removeCantidad", item);
        } else {
          let divisor =
            Number(item.unidades) / Number(item.SalesQtyPerPackUnit);
          let res = parseInt(Number(divisor)) + 1;
          item.unidades = res * parseInt(Number(item.SalesQtyPerPackUnit));

          this.$store.commit("carrito/removeCantidad", item);

          let index = this.$store.state.carrito.data.findIndex(
            (i) => i.ItemCode === item.ItemCode
          );
          console.log("respStoc");
          console.log(respStoc);
          console.log(item.InStock);
          if ((index !== -1) && (respStoc)) {
            // Actualizar la posición (por ejemplo, 'unidades') usando el índice
            this.carrito.data[index].error = "";
          } else if (item.TipoElementosId == 5 && respStoc == false) {
            this.carrito.data[index].error = "";
            this.carrito.data[index].unidades = item.InStock;
          }
        }
      } else {
        item.unidades = 0;
        item.error = "El valor ingresado debe ser mayor que 0";
      }
      this.mergeElementos();
      this.$forceUpdate();
    },
    /*async incrementStock(item) {

      if (item.TipoElementosId == 5) {
        let respStoc = await this.$validarCantStockCarrito(item, 'i');

        if (respStoc == false) {
          return;
        }
      }


      if (!item.error) {
        this.$store.commit("carrito/addCantidad", item);
      }
      this.mergeElementos();
      this.$forceUpdate();
    },*/
    // Métodos relacionados al carrito
    carritoVaciar() {
      this.$store.commit("carrito/remove");
      this.dialogs.carritoVaciar = false;
      this.mergeElementos();
      this.$forceUpdate();
    },
    dialogEliminar(itemId) {
      this.$set(this.dialogs, itemId, true);
    },
    dialogVaciar() {
      this.dialogs.carritoVaciar = true;
    },
    dialogValidate() {
      //hacer validaciones sino cumple, muestra modal

      let index = this.$store.state.carrito.data.findIndex(
        (i) => i.error !== ""
      );

      if (index !== -1) {
        // Actualizar la posición (por ejemplo, 'unidades') usando el índice
        this.dialogs.carritoValidate = true;
      } else {


        if (this.$canSap("venderElementosAnmac"))
        {
          this.$router.push({name: "carrito_detalle_pedido_anmac"});
        }
        else
        {
          this.$router.push({name: "carrito_detalle_pedido"});
        }


      }
    },

    removeItem(item) {
      this.$store.commit("carrito/removeItem", item);
      this.mergeElementos();
      this.$forceUpdate();
      this.$delete(this.dialogs, item.ItemCode);
    },

    handleKeydown(event, item) {
      this.$validarSoloNumeros(event);
      item.previousValue = item.unidades;
    },
    capitalizeFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
};
</script>
