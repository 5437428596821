import { render, staticRenderFns } from "./FormClient.vue?vue&type=template&id=36448860&scoped=true&"
import script from "./FormClient.vue?vue&type=script&lang=js&"
export * from "./FormClient.vue?vue&type=script&lang=js&"
import style0 from "./FormClient.vue?vue&type=style&index=0&id=36448860&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36448860",
  null
  
)

export default component.exports