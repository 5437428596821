var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","transition":"custom-bottom-transition","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Información del cliente")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticStyle:{"overflow-y":"scroll","max-height":"78vh"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pt-0 pb-1 mb-1 card-border",attrs:{"outlined":""}},[_c('span',{staticClass:"card-span"},[_vm._v("Datos personales")]),_c('v-card-text',{},[_c('v-row',{staticClass:"mt-4 my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['tipoDocumento'] }},[_vm._v("Tipo de documento")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['numeroDocumento'] }},[_vm._v("N° de documento")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['nombreApellido'] }},[_vm._v("Nombre y Apellido")])])],1),_c('v-row',{staticClass:"mt-1 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-select',{ref:"tipoDocumento",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":_vm.disableSelect,"items":[
                      { text: 'CUIT', value: 80 },
                      { text: 'CDI Extranjero', value: 87 },
                      { text: 'Pasaporte', value: 94 }
                    ],"rules":[ _vm.$rulesRequerido ]},on:{"input":function($event){return _vm.validateField('tipoDocumento')}},model:{value:(_vm.form.tipoDocumento),callback:function ($$v) {_vm.$set(_vm.form, "tipoDocumento", $$v)},expression:"form.tipoDocumento"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.documentMask),expression:"documentMask"}],ref:"numeroDocumento",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":_vm.mode === 'view' || _vm.mode === 'edit',"rules":_vm.mode === 'create' ? _vm.numeroDocumentoRules : [],"error-messages":_vm.mode === 'create' ? _vm.customErrorMessage : ''},on:{"input":function($event){return _vm.validateField('numeroDocumento')}},model:{value:(_vm.form.numeroDocumento),callback:function ($$v) {_vm.$set(_vm.form, "numeroDocumento", $$v)},expression:"form.numeroDocumento"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"nombreApellido",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesMax50,
																]},on:{"input":function($event){return _vm.validateField('nombreApellido')}},model:{value:(_vm.form.nombreApellido),callback:function ($$v) {_vm.$set(_vm.form, "nombreApellido", $$v)},expression:"form.nombreApellido"}})],1)],1)],1)],1),_c('br'),_c('br'),_c('v-card',{staticClass:"pt-0 pb-1 mb-1 card-border",attrs:{"outlined":""}},[_c('span',{staticClass:"card-span"},[_vm._v("Datos de dirección")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-2 my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['calle'] }},[_vm._v("Calle")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['numero'] }},[_vm._v("Número")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['piso'] }},[_vm._v("Piso")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['depto'] }},[_vm._v("Depto")])])],1),_c('v-row',{staticClass:"mt-1 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"calle",staticClass:"px-6 mx-4",attrs:{"type":"text","dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesMax50,
																]},on:{"input":function($event){return _vm.validateField('calle')}},model:{value:(_vm.form.calle),callback:function ($$v) {_vm.$set(_vm.form, "calle", $$v)},expression:"form.calle"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"numero",staticClass:"px-6 mx-4",attrs:{"type":"number","dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesNumericos
																]},on:{"keydown":_vm.permitirSoloNumeros,"input":function($event){return _vm.validateField('numero')}},model:{value:(_vm.form.numero),callback:function ($$v) {_vm.$set(_vm.form, "numero", $$v)},expression:"form.numero"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"piso",staticClass:"px-6 mx-4",attrs:{"type":"number","dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesAlfaNum,
																	_vm.$rulesMax50,
																]},on:{"keydown":_vm.permitirSoloNumeros,"input":function($event){return _vm.validateField('piso')}},model:{value:(_vm.form.piso),callback:function ($$v) {_vm.$set(_vm.form, "piso", $$v)},expression:"form.piso"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"depto",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesAlfaNum,
																	_vm.$rulesMax50,
																]},on:{"input":function($event){return _vm.validateField('depto')}},model:{value:(_vm.form.depto),callback:function ($$v) {_vm.$set(_vm.form, "depto", $$v)},expression:"form.depto"}})],1)],1),_c('v-row',{staticClass:"mt-6 my-0 pt-1"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['pais'] }},[_vm._v("Pais")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['provincia'] }},[_vm._v("Provincia")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['ciudad'] }},[_vm._v("Ciudad")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['cp'] }},[_vm._v("C.P.")])])],1),_c('v-row',{staticClass:"mt-1 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"pais",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":true,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesAlfaNum,
																	_vm.$rulesMax50,
																]},on:{"input":function($event){return _vm.validateField('pais')}},model:{value:(_vm.form.pais),callback:function ($$v) {_vm.$set(_vm.form, "pais", $$v)},expression:"form.pais"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{ref:"provincia",staticClass:"px-6 mx-4",attrs:{"items":_vm.provincias,"item-value":"id","item-text":"nombre","rules":[_vm.$rulesRequerido],"disabled":_vm.disabled,"dense":"","outlined":""},on:{"input":function($event){return _vm.validateField('provincia')}},model:{value:(_vm.form.provincia),callback:function ($$v) {_vm.$set(_vm.form, "provincia", $$v)},expression:"form.provincia"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"ciudad",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesAlfaNum,
																	_vm.$rulesMax50,
																]},on:{"input":function($event){return _vm.validateField('ciudad')}},model:{value:(_vm.form.ciudad),callback:function ($$v) {_vm.$set(_vm.form, "ciudad", $$v)},expression:"form.ciudad"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"cp",staticClass:"px-6 mx-4",attrs:{"type":"number","dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesNumericos,
																	_vm.$rulesMax50,
																]},on:{"keydown":_vm.permitirSoloNumeros,"input":function($event){return _vm.validateField('cp')}},model:{value:(_vm.form.cp),callback:function ($$v) {_vm.$set(_vm.form, "cp", $$v)},expression:"form.cp"}})],1)],1)],1)],1),_c('br'),_c('br'),_c('v-card',{staticClass:"pt-0 pb-1 mb-1 card-border",attrs:{"outlined":""}},[_c('span',{staticClass:"card-span"},[_vm._v("Datos de contacto")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-2 my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['telefono'] }},[_vm._v("Teléfono")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['celular'] }},[_vm._v("Celular")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"px-6 mx-4",class:{ 'color-red': _vm.errors['mail'] }},[_vm._v("Mail")])])],1),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"telefono",staticClass:"px-6 mx-4",attrs:{"type":"number","dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesMax50,
																]},on:{"keydown":_vm.permitirSoloNumeros,"input":function($event){return _vm.validateField('telefono')}},model:{value:(_vm.form.telefono),callback:function ($$v) {_vm.$set(_vm.form, "telefono", $$v)},expression:"form.telefono"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"celular",staticClass:"px-6 mx-4",attrs:{"type":"number","dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesNumericos,
																	_vm.$rulesMax50,
																]},on:{"keydown":_vm.permitirSoloNumeros,"input":function($event){return _vm.validateField('celular')}},model:{value:(_vm.form.celular),callback:function ($$v) {_vm.$set(_vm.form, "celular", $$v)},expression:"form.celular"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"3"}},[_c('v-text-field',{ref:"mail",staticClass:"px-6 mx-4",attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"rules":[
																	_vm.$rulesRequerido,
																	_vm.$rulesMail,
																	_vm.$rulesMax50,
																]},on:{"input":function($event){return _vm.validateField('mail')}},model:{value:(_vm.form.mail),callback:function ($$v) {_vm.$set(_vm.form, "mail", $$v)},expression:"form.mail"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],attrs:{"color":"primary"},on:{"click":_vm.openDialogConfirm}},[_vm._v("Guardar")])],1),_c('dialog-form-validate',{attrs:{"bodyText":_vm.bodyText},on:{"input":function($event){_vm.dialogs['formValidate'] = $event}},model:{value:(_vm.dialogs['formValidate']),callback:function ($$v) {_vm.$set(_vm.dialogs, 'formValidate', $$v)},expression:"dialogs['formValidate']"}}),_c('dialog-form-completed',{attrs:{"title":"Acción completada","info":"Los datos se guardaron con exito","icon":"mdi-check","color":"#0078D4"},on:{"close":_vm.closeDialog,"confirm":function($event){return _vm.closeDialog()}},model:{value:(_vm.dialogs['formCompleted']),callback:function ($$v) {_vm.$set(_vm.dialogs, 'formCompleted', $$v)},expression:"dialogs['formCompleted']"}}),_c('dialog-form-confirm',{on:{"close":_vm.handleDialogClose},model:{value:(_vm.dialogs['formConfirm']),callback:function ($$v) {_vm.$set(_vm.dialogs, 'formConfirm', $$v)},expression:"dialogs['formConfirm']"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }